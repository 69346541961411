import {PageLayout} from "@components";
import {PartnersV2} from "../../landingpagev2/_components/_Partners/_Partners";
import {JoinInstitutionsV2} from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import React from "react";
import {Demo, GetWorkDone} from ".././biopharmaceutical";
import {StaticImage} from "gatsby-plugin-image";
import FeatureCard, {FeatureCardLayout} from "@components/FeatureCard/FeatureCard";
import FeatureSummary from "@components/FeatureSummary/FeatureSummary";
import {FeatureSummaryPattern} from "@icons";
import * as styles from "../../products/orders/orders.module.scss";
import Margins from "@components/Margins/Margins";


export default function BioTech () {
    const demoProps = {
        title: "Optimize industrial biotech research and development",
        subtitle: "Genemod offers advanced tools designed to significantly streamline your research activities.",
        srcImg: "gloves.png",
        disableBackgroundShapes: true,
    }

    const featureCardContent = {
        title: "Industrial biotech: vital for global sustainability and net-zero targets",
        list: [
            "Utilize Genemod's solutions to streamline the development of sustainable biotech processes.\n" ,
            "Genemod's inventory management platform will unlock new potential in industrial biotech research.\n" ,
            "Adapt and scale your biotech research effortlessly with Genemod's flexible tools.\n" ,
        ],
        image: (
            <StaticImage
                src={"../../../assets/images/landing_page/products_genemod_ai.png"}
                alt="Easily Manage and Submit Orders"
            />
        ),
    }

    const featureSummaryContent = {
        title: "Centralized management in one system",
        list: [
                "Virtual freezer setup.",
                "Consumables management.",
                "Order management.",
        ],
        sublist: [
            "Create a virtual representation of your actual freezer with adjustable shelves, racks, and cryoboxes, achieving an exact match for every item and physical component.",
            "Craft detailed layouts of your laboratory spaces for efficient handling of industrial biotechnology consumables, enhancing resource efficiency and simplifying research operations.",
            "Provide full clarity and comprehensive record-keeping for each order, ensuring smooth operation and reducing the potential for any workflow interruptions."
        ],
        imageLeft: true,
        img: <FeatureSummaryPattern/>
    }

    const getWorkDoneContent = {
        title: "Comprehensive operation in one platform",
        subtitle: "Explore our industrial biotech laboratory information management systems, featuring Genemod's inventory, electronic notebook, sample management, AI platform, and additional functionalities.",
        skewTop: false,
        skewBottom: false,
        showSquares: false,
        showCentralizedPlatformV2: true,
    }

    const footerContent = {
        headerText: "Powered by Genemod",
        subheaderText: "Our Enterprise plans help companies ensure data integrity and improve collaboration institution-wide. Each subscription is acustom-built, expert-driven foundation of your lab automation program.",
    }

    return (
        <PageLayout
            seoOptions={{type: "PREDEFINED", pageName: "biotech"}}
        >
            <Margins>
            <Demo {...demoProps}/>
            <FeatureCardLayout className={styles.cards}>
                <FeatureCard {...featureCardContent} orientation={"left"}/>
            </FeatureCardLayout>
            </Margins>
            <FeatureSummary{...featureSummaryContent}/>
            <Margins>
            <GetWorkDone type={"white"} {...getWorkDoneContent}/>
            <PartnersV2 includeHighPerformer/>
            <JoinInstitutionsV2 {...footerContent}/>
            </Margins>
        </PageLayout>
    );
}